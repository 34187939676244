import ApiMakerConfig from "@kaspernj/api-maker/build/config"
import AppHistory from "@kaspernj/api-maker/build/history"
import BodyPortal from "components/body-portal"
import {callbacksHandler} from "on-location-changed/build/callbacks-handler"
import CommandsPool from "@kaspernj/api-maker/build/commands-pool"
import currenciesCollection from "shared/currencies-collection"
import HayaSelectConfig from "haya-select/build/config"
import I18n from "shared/i18n"
/* eslint-disable sort-imports */

// Set global.applicationHistory so that on-location-changed will listen on it
callbacksHandler.connectReactRouterHistory(AppHistory)


// Use our own logging tool to log errors in tests
import "shared/error-logger"


// HayaSelect
HayaSelectConfig.setBodyPortal(BodyPortal)


// API maker
ApiMakerConfig.setCurrenciesCollection(currenciesCollection)
ApiMakerConfig.setHistory(AppHistory)

CommandsPool.current().globalRequestData.locale = I18n.locale


// React layout and application
import "shared/google-tag-manager"
import "shared/react-native-vector-icons"
import "shared/source-maps"
import "my/application"
import "controllers"
import "stylesheets/application"

require.context("../components", true, /\/(.+)\.(s|)css$/)
require.context("../my", true, /\/(.+)\.(s|)css$/)
